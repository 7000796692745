/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

body {
  overflow: hidden; }

nav {
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 40px; }
  nav ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    nav ul li {
      list-style: none;
      margin: 0 15px; }
      nav ul li a {
        color: #BEBBAB;
        font-family: 'Bitter', serif;
        font-size: 20px;
        text-decoration: none; }
        nav ul li a:hover {
          text-decoration: none;
          color: #BEBBAB; }

header {
  background: transparent;
  background-size: cover;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden; }
  header .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    header .video video {
      object-fit: cover; }
    header .video::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #010101;
      opacity: .9; }
  header .title {
    position: relative;
    z-index: 2; }
  header h1 {
    color: #BAAA49;
    text-align: center;
    font-size: 88px;
    text-transform: uppercase; }
    header h1 .text {
      overflow: hidden; }
      header h1 .text br {
        display: none; }
      header h1 .text span {
        transform: translateY(150px);
        display: inline-block;
        letter-spacing: -12px;
        font-family: 'Pirata One', cursive !important;
        transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0s, -webkit-transform 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0s, -moz-transform 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0s, -o-transform 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0s; }
        header h1 .text span:nth-child(1) {
          -webkit-transition-delay: 0ms;
          -o-transition-delay: 0ms;
          transition-delay: 0ms; }
        header h1 .text span:nth-child(2) {
          -webkit-transition-delay: 0ms;
          -o-transition-delay: 0ms;
          transition-delay: 0ms; }
        header h1 .text span:nth-child(3) {
          -webkit-transition-delay: 50ms;
          -o-transition-delay: 50ms;
          transition-delay: 50ms; }
        header h1 .text span:nth-child(4) {
          -webkit-transition-delay: 100ms;
          -o-transition-delay: 100ms;
          transition-delay: 100ms; }
        header h1 .text span:nth-child(5) {
          -webkit-transition-delay: 150ms;
          -o-transition-delay: 150ms;
          transition-delay: 150ms; }
        header h1 .text span:nth-child(6) {
          -webkit-transition-delay: 200ms;
          -o-transition-delay: 200ms;
          transition-delay: 200ms; }
        header h1 .text span:nth-child(7) {
          -webkit-transition-delay: 250ms;
          -o-transition-delay: 250ms;
          transition-delay: 250ms; }
        header h1 .text span:nth-child(8) {
          -webkit-transition-delay: 300ms;
          -o-transition-delay: 300ms;
          transition-delay: 300ms; }
        header h1 .text span:nth-child(9) {
          -webkit-transition-delay: 350ms;
          -o-transition-delay: 350ms;
          transition-delay: 350ms; }
        header h1 .text span:nth-child(10) {
          -webkit-transition-delay: 400ms;
          -o-transition-delay: 400ms;
          transition-delay: 400ms; }
        header h1 .text span:nth-child(11) {
          -webkit-transition-delay: 450ms;
          -o-transition-delay: 450ms;
          transition-delay: 450ms; }
        header h1 .text span:nth-child(12) {
          -webkit-transition-delay: 500ms;
          -o-transition-delay: 500ms;
          transition-delay: 500ms; }
        header h1 .text span:nth-child(13) {
          -webkit-transition-delay: 550ms;
          -o-transition-delay: 550ms;
          transition-delay: 550ms; }
        header h1 .text span:nth-child(14) {
          -webkit-transition-delay: 600ms;
          -o-transition-delay: 600ms;
          transition-delay: 600ms; }
        header h1 .text span:nth-child(15) {
          -webkit-transition-delay: 650ms;
          -o-transition-delay: 650ms;
          transition-delay: 650ms; }
        header h1 .text span:nth-child(16) {
          -webkit-transition-delay: 700ms;
          -o-transition-delay: 700ms;
          transition-delay: 700ms; }
        header h1 .text span:nth-child(17) {
          -webkit-transition-delay: 750ms;
          -o-transition-delay: 750ms;
          transition-delay: 750ms; }
        header h1 .text span:nth-child(18) {
          -webkit-transition-delay: 800ms;
          -o-transition-delay: 800ms;
          transition-delay: 800ms; }
        header h1 .text span:nth-child(19) {
          -webkit-transition-delay: 850ms;
          -o-transition-delay: 850ms;
          transition-delay: 850ms; }
        header h1 .text span:nth-child(20) {
          -webkit-transition-delay: 900ms;
          -o-transition-delay: 900ms;
          transition-delay: 900ms; }
        header h1 .text span:nth-child(21) {
          -webkit-transition-delay: 950ms;
          -o-transition-delay: 950ms;
          transition-delay: 950ms; }
        header h1 .text span:nth-child(22) {
          -webkit-transition-delay: 1000ms;
          -o-transition-delay: 1000ms;
          transition-delay: 1000ms; }
        header h1 .text span:nth-child(23) {
          -webkit-transition-delay: 1050ms;
          -o-transition-delay: 1050ms;
          transition-delay: 1050ms; }
        header h1 .text span:nth-child(24) {
          -webkit-transition-delay: 1100ms;
          -o-transition-delay: 1100ms;
          transition-delay: 1100ms; }
    header h1.active .text span {
      transform: translateY(0); }
  header .words {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden; }
    header .words span {
      position: absolute;
      font-family: 'Pirata One', cursive !important;
      display: inline-block;
      color: #FFFFFF;
      text-transform: uppercase;
      font-size: 180px;
      opacity: 0;
      transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0s, all 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0s, all 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0s, all 1.5s cubic-bezier(0.19, 1, 0.22, 1) 0s; }
      header .words span:nth-child(1) {
        left: 0;
        top: 0;
        transform: translateX(-50%);
        -webkit-transition-delay: 1000ms;
        -o-transition-delay: 1000ms;
        transition-delay: 1000ms; }
      header .words span:nth-child(2) {
        right: 0;
        top: 0;
        transform: translateX(20%);
        -webkit-transition-delay: 1600ms;
        -o-transition-delay: 1600ms;
        transition-delay: 1600ms; }
      header .words span:nth-child(3) {
        left: 0;
        top: 0;
        transform: translateX(-50%) translateY(250px);
        -webkit-transition-delay: 1800ms;
        -o-transition-delay: 1800ms;
        transition-delay: 1800ms; }
      header .words span:nth-child(4) {
        right: 0;
        top: 0;
        transform: translateX(20%) translateY(250px);
        -webkit-transition-delay: 1200ms;
        -o-transition-delay: 1200ms;
        transition-delay: 1200ms; }
      header .words span:nth-child(5) {
        left: 0;
        top: 0;
        transform: translateX(-40%) translateY(520px);
        -webkit-transition-delay: 1400ms;
        -o-transition-delay: 1400ms;
        transition-delay: 1400ms; }
      header .words span:nth-child(6) {
        right: 0;
        top: 0;
        transform: translateX(10%) translateY(520px);
        -webkit-transition-delay: 2000ms;
        -o-transition-delay: 2000ms;
        transition-delay: 2000ms; }
    header .words.active span {
      opacity: 0.1 !important; }

section.sobre {
  background: #0A0D0F;
  padding: 55px 0; }
  section.sobre .block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    section.sobre .block .text {
      position: absolute; }
      section.sobre .block .text h2 {
        font-family: 'Pirata One', cursive !important;
        color: #BEBBAB;
        font-size: 75px;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 2px 2px #766E41; }
  section.sobre p {
    font-family: 'Bitter', serif;
    color: #BEBBAB;
    font-size: 20px; }

section.espaco {
  background: #0A0D0F url(../img/espaco.png);
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 190px; }
  section.espaco h3 {
    color: #BEBBAB;
    font-family: 'Pirata One', cursive !important;
    font-size: 50px; }
  section.espaco p {
    font-family: 'Bitter', serif;
    color: #BEBBAB;
    font-size: 20px; }
  section.espaco .sticky {
    position: sticky;
    top: 80px; }
  section.espaco .image {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr); }
    section.espaco .image div {
      position: relative; }
      section.espaco .image div.text-right {
        text-align: right; }
      section.espaco .image div:nth-child(1) {
        left: 50px; }
      section.espaco .image div:nth-child(2) {
        top: -170px;
        right: 50px; }
      section.espaco .image div:nth-child(3) {
        left: 150px; }
      section.espaco .image div:nth-child(4) {
        right: 80px; }
      section.espaco .image div:nth-child(5) {
        top: 80px; }

section.produtos {
  background: #0a0d0f;
  background: linear-gradient(180deg, #0a0d0f 0%, #1b2024 20%);
  padding-top: 95px;
  padding-bottom: 95px; }
  section.produtos h4 {
    color: #BEBBAB;
    font-family: 'Pirata One', cursive !important;
    font-size: 50px; }
  section.produtos .slide .item h5 {
    color: #BEBBAB;
    font-family: 'Pirata One', cursive !important;
    font-size: 82px;
    text-transform: uppercase; }
  section.produtos .slide .item p {
    font-family: 'Bitter', serif;
    color: #BEBBAB;
    font-size: 17px; }
  section.produtos .slide .item .text .title {
    font-family: 'Bitter', serif;
    color: #BEBBAB;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px; }
    section.produtos .slide .item .text .title span {
      color: #BAAA49; }
    section.produtos .slide .item .text .title h5 {
      color: #BAAA49;
      font-size: 30px;
      font-family: 'Bitter', serif !important;
      font-weight: 600; }
    section.produtos .slide .item .text .title img {
      margin-top: 5px; }
  section.produtos .slide .slick-slide .image {
    position: relative; }
    section.produtos .slide .slick-slide .image .product {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0; }
    section.produtos .slide .slick-slide .image .fundo {
      opacity: 0; }
  section.produtos .slide .slick-slide.slick-active .image .product {
    animation: product 2s 1s forwards; }
  section.produtos .slide .slick-slide.slick-active .image .fundo {
    animation: fundo 2s 0.5s forwards; }
  section.produtos .slide .slick-dots li {
    width: 40px;
    height: 8px;
    background: transparent;
    border: 1px #BAAA49 solid;
    border-radius: 10px; }
    section.produtos .slide .slick-dots li.slick-active {
      background: #BAAA49; }
  section.produtos .slide .slick-prev, section.produtos .slide .slick-next {
    top: 100%;
    z-index: 1; }
    section.produtos .slide .slick-prev::before, section.produtos .slide .slick-next::before {
      display: none; }
    section.produtos .slide .slick-prev svg path, section.produtos .slide .slick-next svg path {
      fill: #BAAA49; }
  section.produtos .slide .slick-prev {
    left: 40%; }
  section.produtos .slide .slick-next {
    right: 40%; }

section.busca {
  background: url(../img/busca.png);
  background-size: cover;
  padding: 155px 0; }
  section.busca h5 {
    color: #766E41;
    font-family: 'Pirata One', cursive !important;
    font-size: 70px;
    margin-top: 25px; }

section.fabrica {
  background: #0a0d0f;
  background: linear-gradient(180deg, #0a0d0f 0%, #1b2024 20%);
  padding-top: 95px;
  padding-bottom: 95px; }
  section.fabrica .foto {
    position: relative; }
    section.fabrica .foto .flit {
      background: #766E41;
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      transition: all 1s;
      transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
      transition-delay: .5s;
      transform: translateX(15px) translateY(15px); }
    section.fabrica .foto .flit2 {
      background: #BEBBAB;
      position: absolute;
      top: 0;
      right: 0;
      width: 0%;
      height: 100%;
      transition: all 1s;
      transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
      transition-delay: 1.5s;
      transform: translateX(-15px) translateY(-15px); }
    section.fabrica .foto img {
      position: relative;
      opacity: 0;
      transition: all 1s;
      transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
      transition-delay: 2.5s; }
    section.fabrica .foto.active .flit, section.fabrica .foto.active .flit2 {
      width: 100%; }
    section.fabrica .foto.active img {
      opacity: 1; }
  section.fabrica h5 {
    color: #BEBBAB;
    font-family: 'Pirata One', cursive !important;
    font-size: 80px;
    text-transform: uppercase;
    margin-top: 25px; }
  section.fabrica p {
    font-family: 'Bitter', serif;
    color: #BEBBAB;
    font-size: 30px; }
  section.fabrica .text {
    text-align: right;
    margin-top: 45px; }
    section.fabrica .text h5 {
      font-size: 40px;
      text-transform: inherit; }
    section.fabrica .text label {
      color: #BAAA49;
      font-family: 'Pirata One', cursive !important;
      font-size: 40px;
      text-transform: uppercase; }
  section.fabrica hr {
    border-bottom: 1px #BAAA49 solid;
    opacity: 1;
    width: 900px;
    margin: 95px auto 0; }
  section.fabrica .folha {
    position: absolute;
    bottom: 0;
    left: 0; }

section.mapa {
  background: #1B2125; }
  section.mapa h5 {
    color: #BEBBAB;
    font-family: 'Pirata One', cursive !important;
    font-size: 40px; }

section.instagram {
  background: #1B2125;
  padding-top: 55px;
  padding-bottom: 95px; }
  section.instagram h5 {
    color: #BEBBAB;
    font-family: 'Pirata One', cursive !important;
    font-size: 40px; }
  section.instagram .slide img {
    margin: 0 auto; }
  section.instagram .slide .slick-dots {
    bottom: -55px; }
    section.instagram .slide .slick-dots li {
      width: 40px;
      height: 8px;
      background: transparent;
      border: 1px #BAAA49 solid;
      border-radius: 10px; }
      section.instagram .slide .slick-dots li.slick-active {
        background: #BAAA49; }
  section.instagram .slide .slick-prev, section.instagram .slide .slick-next {
    top: 112%;
    z-index: 1; }
    section.instagram .slide .slick-prev::before, section.instagram .slide .slick-next::before {
      display: none; }
    section.instagram .slide .slick-prev svg path, section.instagram .slide .slick-next svg path {
      fill: #BAAA49; }
  section.instagram .slide .slick-prev {
    left: 38%; }
  section.instagram .slide .slick-next {
    right: 38%; }

footer {
  background: #1B2125;
  padding-top: 95px;
  padding-bottom: 75px; }
  footer nav {
    position: relative;
    top: 0; }

.zoo {
  background: #1B2125;
  padding: 25px 0; }
  .zoo p {
    color: #FFF;
    margin-bottom: 0; }
    .zoo p img {
      width: 80px;
      margin-left: 10px; }

.modal {
  background: #0A0D0F;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center; }
  .modal h5 {
    color: #BAAA49;
    font-family: 'Pirata One', cursive !important;
    font-size: 56px;
    text-transform: uppercase;
    margin-top: 5px; }
  .modal button {
    background: transparent;
    color: #BAAA49;
    font-family: 'Bitter', serif;
    border: 1px #BAAA49 solid;
    padding: 9px 50px;
    text-transform: uppercase;
    font-size: 20px;
    border-radius: 5px;
    margin: 5px;
    margin-top: 30px;
    transition: all .3s; }
    .modal button:hover {
      background: #BAAA49;
      border: 1px #BAAA49 solid;
      color: #000; }
  .modal.disabeld {
    display: none !important; }

@keyframes product {
  0% {
    opacity: 0;
    transform: rotate(-10deg) scale(0.8); }
  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1); } }

@keyframes fundo {
  0% {
    opacity: 0;
    transform: rotate(10deg) scale(0.9); }
  100% {
    opacity: 1;
    transform: rotate(0deg) scale(1); } }

@media (max-width: 768px) {
  nav ul {
    display: none; }
  header {
    height: 90vh; }
    header h1 {
      font-size: 47px; }
      header h1 .text br {
        display: block; }
    header .words span {
      font-size: 60px;
      top: 10% !important; }
  .modal h5 {
    font-size: 35px;
    margin-top: 20px; }
  section.sobre .block .text h2 {
    font-size: 50px; }
  section.espaco {
    padding-top: 70px; }
    section.espaco h3 {
      font-size: 38px; }
    section.espaco p {
      font-size: 22px; }
    section.espaco .sticky {
      position: initial; }
    section.espaco .image {
      grid-template-rows: repeat(2, 1fr); }
      section.espaco .image div:nth-child(1), section.espaco .image div:nth-child(3), section.espaco .image div:nth-child(5) {
        left: 0;
        top: 0; }
      section.espaco .image div:nth-child(2), section.espaco .image div:nth-child(4), section.espaco .image div:nth-child(6) {
        right: 0;
        top: 0; }
      section.espaco .image div img {
        width: 90%;
        margin-bottom: 15px; }
  section.produtos h4 {
    font-size: 40px; }
    section.produtos h4 br {
      display: none; }
  section.produtos .slide {
    margin-top: 35px; }
    section.produtos .slide .item h5 {
      text-align: center;
      font-size: 50px; }
    section.produtos .slide .item .text {
      text-align: center; }
      section.produtos .slide .item .text .title img {
        margin: 5px auto 0; }
    section.produtos .slide .slick-next, section.produtos .slide .slick-prev {
      top: 105%; }
  section.busca {
    padding: 75px 0; }
    section.busca h5 {
      font-size: 50px; }
  section.fabrica {
    text-align: center; }
    section.fabrica h5 {
      font-size: 50px; }
      section.fabrica h5 br {
        display: none; }
    section.fabrica .foto {
      width: 90%;
      margin: 35px auto 0; }
    section.fabrica .folha {
      width: 50%;
      position: relative;
      bottom: -55px; }
    section.fabrica .text {
      text-align: center; }
    section.fabrica hr {
      width: 90%;
      margin: 30px auto 0; }
  section.instagram {
    padding-bottom: 0; }
    section.instagram h5 br {
      display: none; } }

@media (min-width: 1099px) and (max-width: 1439px) {
  header h1 {
    font-size: 82px;
    margin-top: 140px; }
    header h1 .text span {
      letter-spacing: -18px; }
  header .words span {
    position: absolute;
    font-family: 'Pirata One', cursive !important;
    display: inline-block;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 145px; }
  section.fabrica .folha {
    width: 20%; } }

@media (min-width: 1440px) and (max-width: 1900px) {
  header h1 {
    font-size: 82px;
    margin-top: 140px; }
    header h1 .text span {
      letter-spacing: -18px; } }
